// See README (https://github.com/Bestowinc/agent-reporting-portal#app-maintenance)
// for specifics on how to implement maintenance messaging
import { MAINTENANCE_MESSAGES } from './constants';

interface MaintenanceMessaging {
  header: string;
  message: string;
}

export const blockTraffic = (): boolean => {
  return process.env?.REACT_APP_BLOCK_TRAFFIC === 'true';
};

const getVariant = (): string => {
  const rawVariant = process.env?.REACT_APP_MESSAGE_VARIANT;

  return rawVariant ? rawVariant.toLowerCase() : 'a';
};

const getVariantOptions = (): string[] => {
  return Object.keys(MAINTENANCE_MESSAGES.messages);
};

export const getMaintenanceMessaging = (): MaintenanceMessaging => {
  const variant = getVariant();
  const options = getVariantOptions();

  if (options.includes(variant)) {
    return {
      header: MAINTENANCE_MESSAGES.header,
      message: MAINTENANCE_MESSAGES.messages[variant]
    };
  }

  // Generic messaging
  return {
    header: MAINTENANCE_MESSAGES.header,
    message: MAINTENANCE_MESSAGES.messages.a
  };
};