import axios, { AxiosRequestConfig } from 'axios';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const axiosRequest = async (
  url: string,
  config?: AxiosRequestConfig
) => {
  try {
    const accessToken = localStorage.getItem('Token');
    Axios.interceptors.request.use(requestConfig => {
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.Authorization = `Bearer ${accessToken}`;

      return requestConfig;
    });

    const response = await Axios({
      url,
      ...config
    });

    return response.data;
  } catch (error) {
    // @ts-ignore
    if (error?.response?.data?.message) {
      // @ts-ignore
      throw new Error(error.response.data.message);
    }

    throw error;
  }
};
