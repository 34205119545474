import React, { useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BESTOW_THEME, ToastContainer } from '@bestowinc/kindred-ds';

import { LoaderLogoWrapper } from './App.styled';
import { IconLoaderLogo } from './components/icons/IconLoaderLogo';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Header } from './Header';
import { ReportDownloadView } from './views/ReportDownloadView';

import { Header as OldHeader } from './components/OLD_THEME/header/views/Header';
import { ReportDownloadView as OldReportDownloadView } from './components/OLD_THEME/components/ReportDownloadView';

import './index.css'
import { isOldTheme } from './utils/utils';
import { useIdentifier } from './utils/featureFlagProvider';
import { useFlags } from './utils/featureFlagProvider';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    ${isOldTheme && `background-color: #fafafa`}
  }
`;

export const App = () => {
  const isCancelled = useRef(false);
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, user } = useAuth0();
  const { identifyUser } = useIdentifier();
  const { salesforceForm, zendeskForm } = useFlags();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        if (!isCancelled.current) {
          localStorage.setItem('Token', token);
        }
      } catch (err: any) {
        if (err.error === 'login_required' && !isCancelled.current) {
          loginWithRedirect();
        }
      }
    })();

    return () => {
      isCancelled.current = true;
    }
  }, [loginWithRedirect, getAccessTokenSilently]);

  useEffect(() => {
    // Keys are initialized as undefined. Wait for them to resolve to inject scripts
    if (salesforceForm !== undefined || zendeskForm !== undefined) {
     if (salesforceForm) {
        const esw = document.createElement('script')
        esw.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js'
        esw.type = 'text/javascript'

        const salesforceScript = document.createElement('script')
        salesforceScript.src = './scripts/salesforceForm.js'

        document.body.appendChild(esw)
        document.body.appendChild(salesforceScript)

        return () => {
          document.body.removeChild(esw)
          document.body.removeChild(salesforceScript)
        }
      }

      if (zendeskForm) {
        const zendeskScript = document.createElement('script')
        zendeskScript.src = './scripts/zendeskForm.js'
        document.body.appendChild(zendeskScript)

        return () => {
          document.body.removeChild(zendeskScript)
        }
      }
    }
  }, [salesforceForm, zendeskForm]);

  useEffect(() => {
    if (user) {
      identifyUser(user.sub ?? '', user.name ?? '', user.email ?? '')
    }
  }, [user, identifyUser])

  if (!isAuthenticated) {
    return (
      <LoaderLogoWrapper>
        <IconLoaderLogo />
      </LoaderLogoWrapper>
    )
  }

  function HeaderComponent() {
    if (isOldTheme) {
      return <OldHeader />
    }

    return <Header />
  }

  function ReportDownloadComponent() {
    if (isOldTheme) {
      return <OldReportDownloadView />
    }

    return <ReportDownloadView />
  }

  return (
    <ThemeProvider theme={BESTOW_THEME}>
      <ToastContainer position="top-center" />
      <HeaderComponent />
      <GlobalStyle />
      <ProtectedRoute>
        <ReportDownloadComponent />
      </ProtectedRoute>
    </ThemeProvider>
  )
};