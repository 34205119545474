import styled from 'styled-components/macro';
import { mediaQueries } from '../utils/constants';
import { Button, Text, Tooltip as UnstyledTooltip } from '@bestowinc/kindred-ds';
import { isOldTheme } from '../utils/utils';

export const Tooltip = styled(UnstyledTooltip)`
  box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.1);
`;

export const DottedSpan = styled.span`
  text-decoration: underline dotted;
`;

export const GrayDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralSemiStrong};
  width: 100vw;
  height: 203px;

  ${mediaQueries.mobile}{
    height: 24px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 22.1875rem;
  padding: 12px 0;
  gap: 12px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.sans};

  ${mediaQueries.tablet} {
    min-width: 360px;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 10%);
  }
`;

export const TimezoneParagraph = styled.span`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin: 0;

  ${mediaQueries.tablet} {
    padding: 0 20px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 2rem 0;
  flex-direction: row;

  ${mediaQueries.mobile} {
    grid-auto-flow: dense;
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }
`;

export const DownloadViewContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.l1.dim};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 800px;
  position: relative;
  justify-self: center;
  padding: 1.5rem;

  ${mediaQueries.tablet} {
    margin-top: -7.6875rem;
    margin-bottom: 2rem;
    width: calc(100vw - 48px);
  }

  ${mediaQueries.desktop} {
    margin-top: -7.6875rem;
    margin-bottom: 2rem;
    width: calc(100vw - 48px);
  }
  
  ${mediaQueries.mobile}{
    grid-auto-flow: row;
    padding: 1rem;
    width: 100vw;
  }
`;

export const ReportTypeButtonSubLabel = styled.span`
  display: flex;
  align-items: center;
  ${({ theme }) => `
  color: ${theme.colors.gray[600]};
  font-size: ${theme.fontSize[12]};
  font-family: ${theme.fontFamily.sans};
  `}
`;

export const ReportTypeSpan = styled.span`
  display: inline-block;
  cursor: pointer;
  ${({ theme }) => `
    font-weight: ${theme.fontWeight.semibold};
    color: ${theme.colors.blue[900]}
  `}
`;

export const ReportSpan = styled.span``;

export const ReportParagraph = styled.div`
  display:flex;
  align-items: center;
  justify-content: end;
  padding-left: 1rem;
  ${({ theme }) => `
    font-size: ${theme.fontSize[14]};
    font-family: ${theme.fontFamily.sans};
    font-weight: ${theme.fontWeight.normal};
    line-height: 18px;
    color: ${theme.colors.gray[600]};
  `}

  ${mediaQueries.mobile} {
    margin-bottom: 25px;
  }
`;

export const DownloadReportButton = styled(Button).attrs({
  variant: 'primary'
})`
  ${({ theme }) => `
    width: 12.8125rem;
    height: 3.125rem;
    font-family: ${theme.fontFamily.sans};
    font-style: normal;
    font-weight: ${theme.fontWeight.semibold};
    font-size: ${theme.fontSize[16]};
    line-height: ${theme.lineHeight.xs};
    padding: unset;
    border-radius: 1px;
  `}

  &:disabled,
  &::after {
    border: none !important;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const DateRangeContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.sans};
  ${isOldTheme && `
    position: absolute;
    top: -20px;
    padding-bottom: 20px;
    z-index:1;
  `}

  ${!isOldTheme && `
    ${mediaQueries.desktop} {
      position: relative;
      top: -2rem;
      background-color: ${({ theme: { colors } }) => colors.white};
    }
  `}
  
`;

export const MainContainerDiv = styled.div`
  display: grid;
`;

export const ContainerDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${mediaQueries.mobile}{
    flex-direction: column-reverse;
    display: flex;
    align-items: self-start;
  }
`;

export const CustomParagraph = styled(Text).attrs({
  element: 'p',
  variant: 'bodyLarge'
})`
  margin-top: 0.5rem;
`;

export const DescriptionSpan = styled.span`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.sans};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize[14]};
    color: ${theme.colors.gray[900]};
    line-height ${theme.lineHeight.med};
    padding-top: 0.75rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.625rem;

  ${mediaQueries.mobile}{
    grid-auto-flow: row;
    width: 100%;
    grid-gap: 0.5rem;
  }
`;