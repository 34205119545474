export const IconCalendar = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg width="20px" height="20px" viewBox="0 0 34 33" {...props}>
    <title>calendar-icon</title>
    <g
      id="MVP"
      stroke="none"
      strokeWidth="1"
      fill="#fff"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Option-A---Latest-Activity-V3"
        transform="translate(-583.000000, -297.000000)"
        stroke="#000"
      >
        <g id="calendar" transform="translate(584.000000, 297.000000)">
          <path
            d="M1,2.7826087 L31,2.7826087 C31.5522847,2.7826087 32,3.23032395 32,3.7826087 L32,31 C32,31.5522847 31.5522847,32 31,32 L1,32 C0.44771525,32 6.76353751e-17,31.5522847 0,31 L0,3.7826087 C-6.76353751e-17,3.23032395 0.44771525,2.7826087 1,2.7826087 Z M6.95652174,0 L6.95652174,6.95652174 M25.0434783,4.4408921e-16 L25.0434783,6.95652174 M0,9.73913043 L32,9.73913043"
            id="calendar-icon"
          />
        </g>
      </g>
    </g>
  </svg>
);
