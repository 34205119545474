import { Button, Text } from '@bestowinc/kindred-ds';
import styled from 'styled-components';
import { IconExit } from './components/icons/IconExit';
import { mediaQueries } from './utils/constants';

import { Menu as UnstyledMenu } from '@bestowinc/kindred-ds';

export const Menu = styled(UnstyledMenu)`
  transform: translate3d(calc(100vw - 340px),71.5px,0px) !important;

  ${mediaQueries.mobile} {
    transform: translate(0px, 71.5px) !important;
    height: calc(100vh - 4.375rem);
    width: 100vw;

    & > ul {
      height: 100%;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.mobile} {
    padding: 1rem;
    bottom: 0;
    position: absolute;
    height: 4.625rem;
    background-color: ${({ theme: { colors } }) => colors.gray[50]};
    width: 100vw;
  }
`;

export const IconContainer = styled.span`
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 1.125rem;
`;

export const MenuContainer = styled.div`
  padding: 1rem;
  grid-auto-flow: row;
  display: grid;

  ${mediaQueries.mobile} {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const MenuSettings = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-top: 1rem;

  ${mediaQueries.mobile} {
    grid-auto-flow: row;
    grid-gap: 2rem;
    padding: 1rem;
  }
`;

export const MenuLink = styled.a`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.sans};
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize[12]};
    text-decoration: underline;
    cursor: pointer;
  `}

  ${mediaQueries.mobile} {
    ${({ theme }) => `
    font-family: ${theme.fontFamily.sans};
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSize[14]};
    color: ${theme.colors.tertiary};
    text-decoration: none;
  `}
  }
  
`;

export const MenuName = styled.span`
  ${({ theme }) => `
    font-weight: ${theme.fontWeight.semibold};
    font-family: ${theme.fontFamily.sans};
    font-size: ${theme.fontSize[12]};

    ${mediaQueries.mobile} {
      font-size: ${theme.fontSize[14]};
    }
  `}
`;

export const MenuEmail = styled.span`
  ${({ theme }) => `
    font-weight: ${theme.fontWeight.normal};
    font-family: ${theme.fontFamily.sans};
    font-size: ${theme.fontSize[12]};
    color: ${theme.colors.gray[700]};
`}
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100vw;
  top: 0;
  z-index: 1;
`;

export const HeaderTitle = styled(Text).attrs({
  element: 'h1',
  variant: 'titleMedium'
})`
 justify-self: end;
`;

export const HeaderSubTitle = styled(Text).attrs({
  element: 'h2',
  variant: 'bodyMedium'
})`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  justify-self: end;
  color: ${({ theme }) => theme.colors.gray[600]};

  ${mediaQueries.mobile} {
    justify-self: start;
  }
`;

export const TitleContainer = styled.div`
  display: grid;
  padding-left: 1.125rem;
`;

export const Wrapper = styled.div`
  height: 5.3125rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.125rem 0 1.5rem;

  ${mediaQueries.mobile}{
    padding: 0 1rem;
  }
`;

export const SessionHeader = styled(Button)`
  background: transparent;
  display: flex; 
  align-items: center;
  padding: 0;

  &:hover{
    background: ${({ theme }) => theme.colors.gray[50]};
  }

  ${mediaQueries.mobile} {
    display: block;
  }
`;

export const LogoWrapper = styled.div`
  display: flex; cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex; align-items: center;
`;

export const CustomIconExit = styled(IconExit)`
  padding-left: 5px
`;