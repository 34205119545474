import {
  asyncWithLDProvider,
  useLDClient,
  useFlags as useLaunchDarklyFlags
} from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

const LDProvider = () => {
  return asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? '',
    flags: {
      'report-version': 1,
      'alternative-version': 2,
      'all-time': false,
      'salesforce-form': false,
      'zendesk-form': false
    }
  });
};

export interface FeatureFlags extends LDFlagSet {
  reportVersion: number;
  alternativeVersion: number;
  allTime: boolean
  salesforceForm: boolean;
  zendeskForm: boolean;
}

export default LDProvider;

export const useFlags = () => {
  return useLaunchDarklyFlags<FeatureFlags>();
};

export const useIdentifier = () => {
  const ldClient = useLDClient();

  const identifyUser = async (key: string, name: string, email: string) => {
    if (ldClient) {
      await ldClient.identify({ key, name, email });
    }
  };

  return {
    identifyUser
  };
};
