import styled from 'styled-components';
import { BESTOW_THEME } from '@bestowinc/kindred-ds';

const { colors, fontFamily } = BESTOW_THEME;

export const MaintenanceWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0 1em;
  font-family: ${fontFamily.sans};
  background-color: ${colors.gray[50]};
  color: ${colors.gray[900]};

  svg {
    width: 60px;
    height: 60px;

    @media (min-width: 560px) {
      width: 64px;
      height: 64px;
    }
    
    @media (min-width: 960) {
      width: 68px;
      height: 68px;
    }
  }
`;

export const MaintenanceHeader = styled.h1`
  margin-top: .4em;
  margin-bottom: .5em;
  color: ${colors.blue[900]};
  font-family: ${fontFamily.serif};
  font-size: 1.3em;
  line-height: 1.5;
  text-align: center;

  @media (min-width: 560px) {
    margin-bottom: .25em;
    font-size: 1.5em;
    line-height: 1.75;
  }

  @media (min-width: 960px) {
    font-size: 1.75em;
    line-height: 1.8;
  }
`;

export const MaintenanceText = styled.p`
  max-width: 630px;
  margin: 0 0 9em 0;
  font-size: .875em;
  line-height: 1.4;
  text-align: center;

  @media (max-width: 560px) {
      max-width: 22em;
  }
`;