import React, { SVGProps } from 'react';

export const IconGlobe = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      role="img"
      aria-label="globe"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#41626B" fillRule="nonzero" transform="translate(-453 -264)">
          <g transform="translate(420 262)">
            <g transform="translate(33.5 2.5)">
              <path d="M6.5 0a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm0 1a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM7.648.272a.5.5 0 01.57.816l-.074.052-1.057.604.097.068c.334.258.552.647.592 1.076l.007.145v.597a.761.761 0 01-.658.754l-.103.007H5.218l-.091-.006-.055-.009-.678-.135a.544.544 0 00-.45.112l-.07.068-.816.957.31 1.017c.06.2.23.346.432.38l.089.007h.887c.252 0 .486.125.626.328l.054.092.406.813a.76.76 0 01.077.418l-.019.107-.109.437a.544.544 0 00.05.394l.058.084.644.773.807-.67a.544.544 0 00.187-.322l.008-.095v-1.28a.76.76 0 01.036-.226l.044-.108.665-1.331-.034-.007a.76.76 0 01-.415-.299l-.055-.092-.285-.57a.76.76 0 01.483-1.074l.103-.02 1.111-.14a.543.543 0 00.393-.25l.045-.088.63-1.576a.5.5 0 01.955.285l-.026.087-.63 1.575c-.19.475-.6.821-1.092.933l-.15.026-.776.098.067.134.053.001c.225 0 .437.1.58.27l.066.09a.76.76 0 01.076.637l-.042.104-.802 1.601v1.22c0 .407-.16.796-.444 1.083l-.112.103-.993.826a.761.761 0 01-.989-.019l-.078-.08-.8-.96c-.278-.336-.4-.773-.34-1.201l.032-.16.087-.35-.301-.602h-.738a1.543 1.543 0 01-1.43-.96l-.048-.136-.35-1.145a.761.761 0 01.087-.624l.065-.088.9-1.056c.33-.387.827-.584 1.327-.534l.15.022.654.131h1.538v-.359c0-.19-.099-.364-.257-.462l-.084-.042-.145-.058a.76.76 0 01-.188-1.309l.09-.06 1.45-.83z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

