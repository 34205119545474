import { AnyAction } from 'redux';
import {
  SET_REPORT_START_DATE,
  SET_REPORT_END_DATE,
  SET_API_VERSION,
  SET_REPORT_VERSION
} from '../actions/agent.actions';

export type AgentReducerState = {
  report: {
    startDate: string | null;
    endDate: string | null;
    apiVersion?: string;
    reportVersion: number;
  };
};

const initialState: AgentReducerState = {
  report: {
    startDate: null,
    endDate: null,
    reportVersion: 1
  }
};

export const agentReducer = (
  state = initialState,
  action: AnyAction
): AgentReducerState => {
  switch (action.type) {
    case SET_REPORT_START_DATE:
      return {
        ...state,
        report: {
          ...state.report,
          startDate: action.payload
        }
      };
    case SET_REPORT_END_DATE:
      return {
        ...state,
        report: {
          ...state.report,
          endDate: action.payload
        }
      }
    case SET_API_VERSION:
      return {
        ...state,
        report: {
          ...state.report,
          apiVersion: action.payload
        }
      }
    case SET_REPORT_VERSION:
      return {
        ...state,
        report: {
          ...state.report,
          reportVersion: action.payload
        }
      }
    default:
      return state;
  }
};
