import styled from 'styled-components/macro';

import { THEME } from '../Theme';
import { mediaQueries } from '../../../utils/constants';
import { CardButton, Paragraph, PrimaryButton } from '../header/Header.style';
import { IconEdit } from '../icons/IconEdit';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0;
  gap: 12px;
  ${mediaQueries.tablet} {
    min-width: 360px;
  }
`;

export const TimezoneParagraph = styled.p`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-size: ${THEME.fontSizes.label};

  ${mediaQueries.tablet} {
    padding: 0 20px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;

  ${mediaQueries.desktop} {
    flex-direction: row;
  }  
`;

export const DownloadViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  
  ${mediaQueries.tablet} {
    max-width: 512px;
    padding: 1.25rem;
  }
  
  @media screen and (min-width: 560px){
    padding-top: 5.625rem;
  }
  
  @media screen and (min-width: 800px){
    padding-left: ${THEME.spacing.l};
    max-width: ${THEME.layouts.containerWidth};
  }
`;

export const ReportTypeButtonSubLabel = styled(Paragraph)`
  color: ${THEME.colors.navy700};
  font-family: ${THEME.fonts.montserrat};
  font-weight: ${THEME.fontWeights.medium};
  font-size: ${THEME.fontSizes.paragraph};
  margin: unset;
  display: flex;
  align-items: center;
`;

export const ReportTypeSpan = styled.span`
  display: inline-block;
  cursor: pointer;
  font-weight: ${THEME.fontWeights.medium};
  font-family: ${THEME.fonts.montserrat};
`;

export const ReportParagraph = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: 1.25rem;
`;

export const DownloadReportButton = styled(PrimaryButton)`
  width: 100%;
  height: 3rem;
  float: left;
  margin-right: 5px;
  
  ${mediaQueries.desktop} {
    width: 12rem;
  }
`;

export const DateRangeContainer = styled.div`
  ${mediaQueries.desktop} {
    position: absolute; 
    top: -20px; 
    padding-bottom: 20px;
  }
`;

export const MainContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerDiv = styled.div`
  position: relative;
`;

export const CustomParagraph = styled(Paragraph)`
  margin: 0 0 1.6rem; 
`;

export const CustomCardButton = styled(CardButton)`
  margin-right: 1.25rem;
`;

export const NoteParagraph = styled(Paragraph)`
  text-align: left;
  margin: 0 0 1.875rem;
`;

export const CustomIconEdit = styled(IconEdit)`
  margin-left: ${THEME.spacing.sm};
`;

export const DescriptionSpan = styled.span`
  margin: 1.25rem 0 0.625rem;
`;
