import { SVGProps } from 'react';

export const IconInfinite = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22.5px" height="10.5px" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5667 9.50005C15.0483 10.1476 15.6638 10.6836 16.3714 11.0716C17.0789 11.4595 17.8619 11.6903 18.6667 11.748C20.0591 11.748 21.3945 11.1949 22.3791 10.2104C23.3636 9.22579 23.9167 7.89043 23.9167 6.49805C23.9167 5.10566 23.3636 3.7703 22.3791 2.78574C21.3945 1.80117 20.0591 1.24805 18.6667 1.24805C14.9167 1.24805 12.6667 6.49805 12.6667 6.49805C12.6667 6.49805 10.4167 11.748 6.66675 11.748C5.27436 11.748 3.939 11.1949 2.95444 10.2104C1.96987 9.22579 1.41675 7.89043 1.41675 6.49805C1.41675 5.10566 1.96987 3.7703 2.95444 2.78574C3.939 1.80117 5.27436 1.24805 6.66675 1.24805C7.47183 1.30677 8.25481 1.53832 8.96233 1.92694C9.66985 2.31556 10.2853 2.85211 10.7667 3.50005"
      stroke="#282624"
    />
  </svg >
);
