import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import momentTz from 'moment-timezone';
import { isInclusivelyAfterDay } from 'react-dates';

// Component
import {
  DownloadViewContainer,
  ReportTypeButtonSubLabel,
  DownloadReportButton,
  CardContainer,
  MainContainerDiv,
  ContainerDiv,
  CustomParagraph,
  CustomCardButton,
  NoteParagraph,
  CustomIconEdit,
  DescriptionSpan
} from './ReportDownloadView.styled'

// Utils
import { useAuth0, User } from '@auth0/auth0-react';
import { ReportViewType } from '../../ReportType';
import { Store } from '../../../redux/types';
import { segmentTrack } from '../../../utils/utils';
import { events } from '../../../log-events';
import { agentReportFetchRequest, setApiVersion, setReportEndDate, setReportStartDate, setReportVersion } from '../../../redux/actions/agent.actions';
import { DatePicker, DatesChangeProps } from '../../DatePicker';
import { IconCalendar } from '../../icons/IconCalendar';
import { IconClock } from '../../icons/IconClock';
import { CardButton, ReportHeader } from '../header/Header.style';
import { ReportType } from './ReportType';
import { DownloadAlternativeReport } from './DownloadAlternativeReport';
import { useFlags } from '../../../utils/featureFlagProvider';

export const DATE_INPUT_UI_FORMAT = 'MM/DD/YY';

export const ReportDownloadView = () => {
  const { user } = useAuth0();
  const today = useMemo(() => moment.utc().endOf('day'), []);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [reportType, setReportType] = useState<ReportViewType>(ReportViewType.last60);
  const isDownloading = useSelector(
    (state: Store) => state.loading.AGENT_REPORT_FETCH
  );

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const [areDatesInvalid, setAreDatesInvalid] = useState(true);

  const [todayTimezone, setTodayTimezone] = useState(moment());
  const [todayUTC, setTodayUTC] = useState(moment().utc());
  const timezoneName = momentTz.tz.guess();
  const timezoneAbbr = momentTz.tz(moment.tz.guess()).zoneAbbr();
  const timeTooltip = 'timeTooltip';

  const timeout = (initialDelay: Moment) => {
    const initialSeconds = initialDelay.seconds();
    const delay = 60 - initialSeconds;

    return setTimeout(() => {
      setTodayTimezone(moment());
      setTodayUTC(moment().utc());
    }, delay);
  };

  useEffect(() => {
    timeout(todayTimezone);
  }, [todayTimezone]);

  useEffect(() => {
    /*
      Disable buttons when:
        - start or end date aren't finished
        - start date is past end date
        - end or start date are past today
        - startDate is the same as endDate - need to have at least 24 hr range
      Note that the date picker UI doesn't let you do any of this invalid stuff, mainly this is for the text inputs
     */
    setAreDatesInvalid(
      !startDate ||
      !endDate ||
      endDate < startDate ||
      endDate > today ||
      startDate > today
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const onDatePickerChange = ({
    startDate: newStartDate,
    endDate: newEndDate
  }: DatesChangeProps) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    segmentTrack(events.CUSTOM_DATE_RANGE_CHANGED, {
      startDate: newStartDate,
      endDate: newEndDate
    }, user);

    dispatch(setReportStartDate(newStartDate?.utc().toISOString() || null));
    dispatch(setReportEndDate(newEndDate?.utc().toISOString() || null));
  };

  const now = moment.utc();
  const last60 = moment.utc().subtract(60, 'days');

  useEffect(() => {
    dispatch(setReportStartDate(last60.toISOString()));
    dispatch(setReportEndDate(now.toISOString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownloadClickHandler = (version: number, user?: User) => {
    dispatch(setApiVersion(`v${ version >= 3 ? 3 : version }`))
    dispatch(setReportVersion(version))
    dispatch(agentReportFetchRequest(user));
  };

  const onLast60DayReportClick = () => {
    segmentTrack(events.LAST_60_DAYS_SELECTED, undefined, user);
    setReportType(ReportViewType.last60);
    dispatch(setReportStartDate(last60.toISOString()));
    dispatch(setReportEndDate(now.toISOString()));
  };

  const onCustomReportClick = () => {
    segmentTrack(events.CUSTOM_DATE_RANGE_SELECTED, undefined, user);
    setReportType(ReportViewType.customDateRange);

    // If they have already set some custom dates, then we treat it as a toggle
    if (reportType === 'last60') {
      setIsDatepickerOpen(true);
    } else if (startDate && endDate) {
      setIsDatepickerOpen(!isDatepickerOpen);
    } else {
      // If they don't have a custom date chosen yet, we force them to leave the pop up open
      setIsDatepickerOpen(true);
    }

    dispatch(setReportStartDate(startDate?.toISOString() || null));
    dispatch(setReportEndDate(endDate?.toISOString() || null));
  };

  const islast60Active = reportType === ReportViewType.last60;
  const isCustomeReportActive = reportType === ReportViewType.customDateRange;
  const isAlternativeDisabled: boolean = isDownloading || (areDatesInvalid && isCustomeReportActive)

  const { reportVersion, alternativeVersion } = useFlags();

  const formatDates = (start: Moment, end: Moment) => {
    return `${start.format(DATE_INPUT_UI_FORMAT)} - ${end.format(DATE_INPUT_UI_FORMAT)} UTC`
  }

  return (
    <MainContainerDiv>
      <DownloadViewContainer>
        <ReportHeader>Download a Business Report</ReportHeader>
        <CustomParagraph>
          Select a date range to view applications opened during a specific timeframe.
        </CustomParagraph>
        <CardContainer>
          <CustomCardButton
            onClick={onLast60DayReportClick}
            isActive={islast60Active}
            // styled components filters out unknown DOM props so we just add a data attribute here for testing
            data-isactive={islast60Active}
          >
            <IconClock
              height="34px"
              width="34px"
            />
            <DescriptionSpan>Last 60 Days</DescriptionSpan>
            <ReportTypeButtonSubLabel>
              {formatDates(last60, now)}
            </ReportTypeButtonSubLabel>
          </CustomCardButton>
          <CardButton
            onClick={onCustomReportClick}
            isActive={isCustomeReportActive}
            // styled components filters out unknown DOM props so we just add a data attribute here for testing
            data-isactive={isCustomeReportActive}
            data-testid="CustomReporButton"
          >
            <IconCalendar
              height="34px"
              width="34px"
            />
            <DescriptionSpan>Custom Date Range</DescriptionSpan>
            <ReportTypeButtonSubLabel>
              {startDate && endDate ? (
                <>
                  {formatDates(startDate, endDate)}
                  <CustomIconEdit />
                </>
              ) : (
                'Set Date Range'
              )}
            </ReportTypeButtonSubLabel>
          </CardButton>
        </CardContainer>
        <ContainerDiv>
          {
            (isCustomeReportActive && isDatepickerOpen) &&
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              areDatesInvalid={areDatesInvalid}
              onSelectClick={() => setIsDatepickerOpen(false)}
              onDatesChange={onDatePickerChange}
              // Don't allow any days past today's date
              isOutsideRange={day =>
                isInclusivelyAfterDay(day, moment.utc().add(1, 'days'))
              }
              closeModal={() => setIsDatepickerOpen(false)}
              isDatePickerOpen={isDatepickerOpen}
              onRequestClose={() => setIsDatepickerOpen(false)}
            />
          }
          <ReportType
            reportViewType={reportType}
            today={todayTimezone}
            todayUTC={todayUTC}
            timezoneName={timezoneName}
            timezoneAbbr={timezoneAbbr}
            tooltipType={timeTooltip}
          />
          <NoteParagraph>
            <b>Note:</b> Report data will show current application statuses.
          </NoteParagraph>
          <DownloadReportButton
            disabled={isAlternativeDisabled}
            onClick={() => onDownloadClickHandler(reportVersion, user)}
          >
            Download Report
          </DownloadReportButton >
          <DownloadAlternativeReport
            isDisabled={isAlternativeDisabled}
            onClick={() => onDownloadClickHandler(alternativeVersion, user)}
          />
        </ContainerDiv>
      </DownloadViewContainer>
    </MainContainerDiv>
  );
};