import { IconButton } from '@bestowinc/kindred-ds';
import styled from 'styled-components/macro';
import { mediaQueries } from '../utils/constants';

export const PrimaryIconButton = styled(IconButton) <{ transparent?: boolean }>`
  ${({ transparent }) => transparent && `
   background-color: transparent;

    &:hover{
      background-color: transparent;
    } 
  `}
`;

export const CardButton = styled.button<{ isActive?: boolean }>`
  border: 2px solid ${({ theme }) => theme.colors.blue['800']};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 9.125rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 0.25rem;

  &:hover {
    background: ${({ theme }) => theme.colors.gray[50]};
  }

  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
    border-right-color: transparent;

    ${mediaQueries.mobile}{
      border-right-color: ${({ theme }) => theme.colors.blue['800']};
    }
  }
  
  &:last-child {
    border-left-color: transparent;
    border-radius: 0px 3px 3px 0px;
    margin-bottom: 0;

    ${mediaQueries.mobile}{
      border-left-color: ${({ theme }) => theme.colors.blue['800']};
    }
  }

  ${({ isActive, theme }) => `
    background-color: ${isActive && theme.colors.blue['100']};
  `}
  
  ${mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
