import createSagaMiddleware from 'redux-saga';
import { AnyAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { agentReducer } from './reducers/agent.reducer';
import { loadingReducer } from './reducers/loading.reducer';
import { Store } from './types';
import { rootSaga } from './sagas/root.saga';

export const reducer = {
  agent: agentReducer,
  loading: loadingReducer
};

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware(), sagaMiddleware];

export const store = configureStore<Store, AnyAction, typeof middleware>({
  reducer,
  devTools: true,
  middleware
});

sagaMiddleware.run(rootSaga);
