import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef, useState } from 'react';

import { USER_ROLES } from '../../../utils/constants';
import { getRolesFromToken } from '../../../utils/utils';
import { DownloadReportButton } from './ReportDownloadView.styled';

export const DownloadAlternativeReport = ({ isDisabled, onClick }) => {
  const isCancelled = useRef(false);
  const [roles, setRoles] = useState<string[]>([]);
  const { getAccessTokenSilently, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        if (!isCancelled.current) {
          setRoles(getRolesFromToken(token));
        }
      } catch (err: any) {
        if (err.error === 'login_required' && !isCancelled.current) {
          loginWithRedirect();
        }
      }
    })()

    return () => {
      isCancelled.current = true;
    }
  }, [getAccessTokenSilently, loginWithRedirect])

  if (!roles.includes(USER_ROLES.ADVISOR_ADMIN) && !user?.email?.endsWith('bestow.com')) {
    return (<></>)
  }

  return (
    <DownloadReportButton
      disabled={isDisabled}
      onClick={onClick}
    >
      Download Alternative Report
    </DownloadReportButton>
  )
}