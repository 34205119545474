import { User } from '@auth0/auth0-react';

export const AGENT_REPORT_FETCH_REQUEST = 'AGENT_REPORT_FETCH_REQUEST';
export const AGENT_REPORT_FETCH_SUCCESS = 'AGENT_REPORT_FETCH_SUCCESS';
export const AGENT_REPORT_FETCH_FAILURE = 'AGENT_REPORT_FETCH_FAILURE';
export const SET_REPORT_START_DATE = 'SET_REPORT_START_DATE';
export const SET_REPORT_END_DATE = 'SET_REPORT_END_DATE';
export const SET_API_VERSION = 'SET_API_VERSION';
export const SET_REPORT_VERSION = 'SET_REPORT_VERSION';

export const agentReportFetchRequest = (user?: User) => ({
  type: AGENT_REPORT_FETCH_REQUEST,
  payload: user
});
export const agentReportFetchSuccess = () => ({
  type: AGENT_REPORT_FETCH_SUCCESS
});

export const agentReportFetchFailure = () => ({
  type: AGENT_REPORT_FETCH_FAILURE
});

export const setReportStartDate = (date: string | null) => ({
  type: SET_REPORT_START_DATE,
  payload: date
});

export const setReportEndDate = (date: string | null) => ({
  type: SET_REPORT_END_DATE,
  payload: date
});

export const setApiVersion = (version?: string) => ({
  type: SET_API_VERSION,
  payload: version
});

export const setReportVersion = (version: number) => ({
  type: SET_REPORT_VERSION,
  payload: version
});