import React from 'react';

export const IconLoaderLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg id="loader-icon" x={0} y={0} viewBox="0 0 124.6 23.9" xmlSpace="preserve" height="80px" {...props}>
    <path
      fill="#F0F2F2"
      className="prefix__st0"
      d="M14.5 11.4c1.4-.9 2.3-2.5 2.3-4.4 0-3-2.2-5.4-5.9-5.4H1.5v20.8h9.9c3.7 0 6.1-2.5 6.1-5.9 0-2.3-1.1-4.1-3-5.1M5.2 5.1h5.2c1.8 0 2.8 1.1 2.8 2.4 0 1.5-.9 2.4-2.8 2.4H5.2V5.1zm0 13.7v-5.3h5.5c2 0 3 1.1 3 2.6s-1 2.6-3 2.6H5.2zM84 1c-6.4 0-10.8 4.9-10.8 11S77.6 22.9 84 22.9c6.3 0 10.7-4.8 10.7-10.9 0-6.1-4.4-11-10.7-11m0 18.3c-4.1 0-7-3.1-7-7.3 0-4.3 2.9-7.4 7-7.4s7 3.1 7 7.4c0 4.3-2.9 7.3-7 7.3M21.1 22.4h10.7l4.8-3.6H24.8v-5.3h9.8V9.9h-9.8V5.1h11V1.6H21.1zM55.3 5.1h7.3v17.3h3.7V5.1h6.3V1.6H60.2zM119.3 1.6l-3.8 14.9-3.9-14.9h-4.1l-3.9 14.9-3.8-14.9H96l5.3 20.8h4.5l3.7-14.6 3.8 14.6h4.5l5.3-20.8zM49.8 10.8s-1-.4-2.9-1.2c-1.8-.8-3.7-1.8-3.7-2.9 0-1.2 1.5-2.1 3.5-2.1 3.2 0 4.9 1.9 4.9 1.9l2.9-2.1s-.4-.6-2-1.6C51 1.6 48.9 1 46.9 1c-4.3 0-7.7 2.4-7.7 5.9 0 2.5 1.5 4.4 4.5 5.6l3.5 1.5c1.9.8 3.1 1.3 3.1 3 0 1.4-1.4 2.3-3.6 2.3-3.8 0-5.8-2.3-5.8-2.3L38 19.1s.2.3.6.6l.1.1c2.1 2 4.9 3.1 7.9 3.1 4.6 0 7.9-2.6 7.9-6.3-.1-2.7-1.5-4.4-4.7-5.8z"
    />
  </svg>
);