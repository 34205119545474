import { Place } from 'react-tooltip';
import { ReactNode } from 'react';
import { TooltipElement } from './Tooltip.styled';

export const Tooltip = ({
  id,
  tooltipContent,
  placement
}: {
  id: string;
  tooltipContent: ReactNode;
  placement?: Place;
}) => (
  <TooltipElement
    event="click"
    id={id}
    place={placement}
    getContent={() => <>{tooltipContent}</>}
    globalEventOff="click"
  />
);