
import { FC } from 'react';
import Modal from 'react-modal';
import { Moment } from 'moment';

import { isMobile, modalStyles } from './../utils/constants';
import { DateRangeContainer } from './../views/ReportDownloadView.styled';
import { DateRangePicker } from './DateRangePicker/DateRangePicker';

export interface DatesChangeProps {
    startDate: Moment | null;
    endDate: Moment | null;
}

interface DatePickerProps {
    startDate: Moment | null;
    endDate: Moment | null;
    areDatesInvalid: boolean;
    onSelectClick: () => void;
    onDatesChange: (args: DatesChangeProps) => void;
    isOutsideRange: (day: Moment) => boolean;
    closeModal: () => void;
    isDatePickerOpen: boolean;
    onRequestClose: () => void;
}

export const DatePicker: FC<DatePickerProps> = ({
    startDate,
    endDate,
    areDatesInvalid,
    onSelectClick,
    onDatesChange,
    isOutsideRange,
    closeModal,
    isDatePickerOpen,
    onRequestClose
}) => {
    const DatePickerBase = (<DateRangeContainer>
        <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            areDatesInvalid={areDatesInvalid}
            onSelectClick={onSelectClick}
            onDatesChange={onDatesChange}
            isOutsideRange={isOutsideRange}
            closeModal={closeModal}
        />
    </DateRangeContainer>);

    if (isMobile) {
        Modal.setAppElement('#root');

        return (<Modal
            isOpen={isDatePickerOpen}
            onRequestClose={onRequestClose}
            style={modalStyles}
        >
            {DatePickerBase}
        </Modal>)
    }

    return (DatePickerBase);
}