import { Text } from '@bestowinc/kindred-ds';
import styled from 'styled-components/macro';

export const ReportHeader = styled(Text).attrs({
    element: 'h1',
    variant: 'titleLarge'
})`
    font-size: 1.75rem;
    font-family:${({ theme }) => theme.fontFamily.serif};
    color: ${({ theme }) => theme.colors.tertiary};
    line-height: 1.875rem;
`;
