export const THEME = {
  fonts: {
    montserrat: 'Montserrat, sans-serif',
    playfair: 'Playfair Display'
  },
  fontSizes: {
    label: '0.75rem',
    paragraph: '0.875rem',
    button: '1rem',
    h2: '1.5rem',
    h1: '1.75rem'
  },
  fontWeights: {
    normal: '400',
    medium: '500',
    bold: '700'
  },
  colors: {
    navy500: 'rgb(11, 53, 65, 0.5)',
    navy600: 'rgb(11, 53, 65, 0.75)',
    navy700: 'rgb(11, 53, 65)',
    secondary700: 'rgb(77, 74, 73)',
    charcoal900: 'rgb(40, 38, 36)',
    actionPressed: '#c6c3c2',
    sage700: 'rgb(194, 211, 210)',
    sage500: 'rgb(194, 211, 210, 0.5)',
    gray500: '#585858',
    gray300: '#DAD7D6',
    gray200: '#edeaea',
    gray100: '#f2f5f4',
    white: '#ffffff',
    mint: '#f2f5f4',
    cta: '#5EBFB8',
    actionHover: '#41626B'
  },
  spacing: {
    xs: '0.5rem',
    sm: '0.75rem',
    m: '1rem',
    l: '2rem'
  },
  layouts: {
    containerWidth: '960px'
  }
};