import { call, put, select, takeEvery } from 'typed-redux-saga';
import moment from 'moment';
import { downloadAgentReportingInfo } from '../../api/ags';
import { downloadUrl, segmentTrack } from '../../utils/utils';
import {
  AGENT_REPORT_FETCH_REQUEST,
  agentReportFetchSuccess,
  agentReportFetchFailure
} from '../actions/agent.actions';
import {
  getApiVersion,
  getReportEndDate,
  getReportStartDate,
  getReportVersion
} from '../selectors/agent.selectors';
import { events } from '../../log-events';
import { addToast, Toast } from '@bestowinc/kindred-ds';
import { createElement } from 'react';

function* getAgentReport(action) {
  try {
    const { payload: user } = action;
    const reportStartDate = yield* select(getReportStartDate);
    const reportEndDate = yield* select(getReportEndDate);
    const apiVersion = yield* select(getApiVersion);
    const reportVersion = yield* select(getReportVersion);
    const startDateBeginning = moment
      .utc(reportStartDate)
      .startOf('day')
      .toISOString();

    const nextDayBeginning = moment
      .utc(reportEndDate)
      .add(1, 'day')
      .startOf('day')
      .toISOString();

    segmentTrack(events.DOWNLOAD_REPORT, {
      startDate: startDateBeginning,
      endDate: nextDayBeginning
    }, user);

    const response = yield* call(() =>
      downloadAgentReportingInfo({
        date_from: startDateBeginning,
        date_to: nextDayBeginning
      }, reportVersion.toString(), apiVersion)
    );

    downloadUrl(response.document_url);
    addToast(createElement(Toast, { type: 'success', children: 'Your download should start automatically.' }));

    yield put(agentReportFetchSuccess());
  } catch (e) {
    addToast(createElement(Toast, { type: 'danger', children: 'Download failed due to network error.' }));
    segmentTrack(events.DOWNLOAD_REPORT_FAILED, {
      error: e
    });

    yield put(agentReportFetchFailure());
  }
}

export function* agentSaga() {
  yield takeEvery(AGENT_REPORT_FETCH_REQUEST, getAgentReport);
}
