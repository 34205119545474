import jwt_decode, { JwtPayload } from 'jwt-decode';
import { USER_ROLES } from './constants';

export const rolesNamespace = 'https://bestow.co/roles';
interface JwtPayloadWithRoles extends JwtPayload {
  [rolesNamespace]: string[];
}

export const downloadUrl = (url: string, name?: string): void => {
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name || 'Download');
  a.click();
};

export const segmentTrack = async (
  eventName: string,
  body?: { [k: string]: any },
  user?: any
) => {
  try {
    if (window.analytics) {
      if (!user) {
        // eslint-disable-next-line no-console
        console.warn(
          'User information in Auth0 SPA doesnt exist. Did you make sure that auth0.getTokenSilently has been triggered?'
        );
      }

      // Prefixing with "arp" to make it easier to look up
      window.analytics.track(`arp_${eventName}`, {
        ...body,
        email: user?.email
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to log to segment.');
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const getRolesFromToken = (token: string) => {
  const decoded: JwtPayloadWithRoles = jwt_decode(token);

  return decoded[rolesNamespace] ?? [];
}

export const isUserAuthorized = (token: string) => {
  let roles = getRolesFromToken(token);

  return roles.some(role => Object.values(USER_ROLES).includes(role))
}

export const isFeatureFlagOn = (flag: string): boolean =>
  process.env.REACT_APP_FEATURES_ENABLED?.includes(flag) || false

export const isOldTheme: boolean = isFeatureFlagOn('OLD_THEME')