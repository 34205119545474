import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  DayPickerRangeController,
  DayPickerRangeControllerShape,
  FocusedInputShape
} from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';

// Styles
import {
  BottomInputFooter,
  ButtonContainer,
  DateRangePickerWrapper,
  IconContainer,
  MobileButtonDivider,
  MobileHeader,
  MobileHeaderContainer,
  TodayDot,
  ClearButton,
  SelectButton,
  RenderDayContentDiv,
  LeftArrow,
  RightArrow
} from './DateRangePicker.styed';

// Components
import { DATE_INPUT_UI_FORMAT } from '../../views/ReportDownloadView';
import { ModalCloseButton } from '../icons/ModalCloseButton';
import { TextInput } from '@bestowinc/kindred-ds';
import { isMobile } from '../../utils/constants';

type DateRangePickerProps = {
  onSelectClick: () => void;
  closeModal: () => void;
  areDatesInvalid: boolean;
} & Pick<
  DayPickerRangeControllerShape,
  | 'startDate'
  | 'endDate'
  | 'onDatesChange'
  | 'isOutsideRange'
  | 'navPrev'
  | 'navNext'
>;

export const DateRangePicker = ({
  startDate,
  endDate,
  areDatesInvalid,
  onDatesChange,
  isOutsideRange,
  navPrev,
  navNext,
  onSelectClick = () => { },
  closeModal
}: DateRangePickerProps) => {
  const today = useMemo(() => moment.utc().endOf('day'), []);
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputShape | null>('startDate');

  const onFocusChange = useCallback(
    (newFocus: FocusedInputShape | null) => {
      setFocusedInput(newFocus || 'startDate');
    },
    [setFocusedInput]
  );

  const [userInputStartDate, setUserInputStartDate] = useState('');
  const [userInputEndDate, setUserInputEndDate] = useState('');

  const handleCustomUserInput = useCallback(
    (type: 'startDate' | 'endDate') => (e: ChangeEvent<HTMLInputElement>) => {
      const newDateValue = moment.utc(
        e.target.value,
        DATE_INPUT_UI_FORMAT,
        true
      );

      if (newDateValue.isValid()) {
        onDatesChange({
          ...(type === 'startDate'
            ? { startDate: newDateValue }
            : { startDate }),
          ...(type === 'endDate' ? { endDate: newDateValue } : { endDate })
        });
      } else {
        onDatesChange({
          ...(type === 'startDate' ? { startDate: null } : { startDate }),
          ...(type === 'endDate' ? { endDate: null } : { endDate })
        });
      }

      if (type === 'startDate') {
        setUserInputStartDate(e.target.value);
      }

      if (type === 'endDate') {
        setUserInputEndDate(e.target.value);
      }
    },
    [onDatesChange, startDate, endDate]
  );

  const renderDayContents = useCallback(
    day => {
      if (day.isSame(today, 'day')) {
        return (
          <RenderDayContentDiv >
            {day.date()}
            <TodayDot />
          </RenderDayContentDiv>
        );
      }

      return day.date();
    },
    [today]
  );

  return (
    <>
      {
        isMobile && (
          <MobileHeaderContainer>
            <MobileHeader>Set date range</MobileHeader>
            <IconContainer onClick={closeModal}>
              <ModalCloseButton />
            </IconContainer>
          </MobileHeaderContainer>
        )
      }
      <DateRangePickerWrapper data-testid="date-picker">
        <DayPickerRangeController
          startDate={startDate}
          endDate={endDate}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
          onDatesChange={onDatesChange}
          isOutsideRange={isOutsideRange}
          renderDayContents={renderDayContents}
          initialVisibleMonth={() => today}
          minimumNights={0}
          numberOfMonths={isMobile ? 1 : 2}
          navPrev={
            navPrev || (
              <IconContainer>
                <LeftArrow
                  height="16px"
                />
              </IconContainer>
            )
          }
          navNext={
            navNext || (
              <IconContainer>
                <RightArrow
                  height="16px"
                />
              </IconContainer>
            )
          }
          orientation={isMobile ? 'vertical' : 'horizontal'}
        />
        <BottomInputFooter>
          <TextInput
            label="Start Date"
            name="startDate"
            id="startDate"
            cleaveOptions={{
              blocks: [0, 2, 2, 2],
              delimiters: ['', '/', '/', '/'],
              numericOnly: true
            }}
            value={
              startDate?.format(DATE_INPUT_UI_FORMAT) || userInputStartDate
            }
            onChange={handleCustomUserInput('startDate')}
            data-isactive={!startDate && !endDate}
          />
          <TextInput
            label="End Date"
            name="endDate"
            id="endDate"
            cleaveOptions={{
              blocks: [0, 2, 2, 2],
              delimiters: ['', '/', '/', '/'],
              numericOnly: true
            }}
            value={endDate?.format(DATE_INPUT_UI_FORMAT) || userInputEndDate}
            onChange={handleCustomUserInput('endDate')}
            data-isactive={!!startDate && !endDate}
          />
          {isMobile && <MobileButtonDivider />}
          <ButtonContainer>
            <ClearButton
              type="button"
              onClick={() => {
                onDatesChange({ startDate: null, endDate: null });
                setUserInputStartDate('');
                setUserInputEndDate('');
              }}
            >
              Clear
            </ClearButton>
            <SelectButton
              type="button"
              onClick={() => onSelectClick()}
              disabled={areDatesInvalid}
            >
              Select
            </SelectButton>
          </ButtonContainer>
        </BottomInputFooter>
      </DateRangePickerWrapper>
    </>
  );
};
