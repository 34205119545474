import { BESTOW_THEME } from '@bestowinc/kindred-ds';

/*
  Our Current Structure is :
  Mobile Sizes: Max=mobileMaxWidth
  Talet Sizes: Min=tabletMinWidth Max=desktopMinWidth
  Desktop Sizes: Min=desktopMinWidth
*/
export const isMobile = window.innerWidth <= Number(BESTOW_THEME.breakpoints.mobile?.max?.replace('px', ''));

export const mediaQueries = {
  mobile: `@media screen and (max-width: ${BESTOW_THEME.breakpoints.mobile?.max})`,
  desktop: `@media screen and (min-width: ${BESTOW_THEME.breakpoints.laptop?.min})`,
  tablet: `@media screen and (min-width: ${BESTOW_THEME.breakpoints.tablet?.min}) and (max-width: ${BESTOW_THEME.breakpoints.tablet?.max})`
};

export const MAINTENANCE_MESSAGES = {
  header: `We're temporarily down for maintenance.`,
  messages: {
    a: `We'll be up and running again as soon as possible. Thanks for your patience as we resolve this issue. `,
    b: `We'll be up and running again in a few hours. Thanks for your patience as we resolve this issue.`,
    c: `We'll let you know when we’re up and running again as soon as possible. Thanks for your patience as we resolve this issue. For more information, please check your email.`
  }
};

export const USER_ROLES = {
  ADVISOR_ADMIN: 'advisor-admin',
  ADVISOR_REPORTING: 'ADVISOR_REPORTING'
};

export const modalStyles = {
  content: {
    top: 'unset',
    right: 'unset',
    left: 'unset',
    bottom: '0',
    width: '100%',
    height: '500px',
    padding: 0,
    border: 'none',
    overflow: 'hidden',
    boxShadow: '0px 3px 12px 0px rgba(0, 0, 0, 0.1)'
  },
  overlay: {
    position: 'absolute',
    top: 'unset',
    right: 'unset',
    left: 'unset',
    bottom: '0',
    width: '100%',
    background: 'none',
    height: '500vh'
  }
};
