import { FC } from 'react';
import {
  Wrapper,
  WelcomeParagraph,
  SessionHeader,
  LogoWrapper,
  ButtonWrapper,
  CustomIconExit,
  PrimaryIconButton
} from './../Header.style'
import { useAuth0 } from '@auth0/auth0-react';
import { isMobile } from '../../../../utils/constants';
import { ARPLogo } from '../../../Logo';

export const Header: FC = () => {
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();

  return (
    <Wrapper>
      <LogoWrapper>
        <ARPLogo data-testid="app-logo" />
      </LogoWrapper>
      <SessionHeader>
        {isAuthenticated ? (
          <>
            {!isMobile && (
              <WelcomeParagraph>
                Welcome,&ensp;{user?.name}!
              </WelcomeParagraph>
            )}

            <ButtonWrapper>
              <PrimaryIconButton
                aria-label="Logout"
                data-testid="logout-button"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <CustomIconExit />
              </PrimaryIconButton>
            </ButtonWrapper>
          </>
        ) : (
          <PrimaryIconButton
            data-testid="signIn-button"
            id="button-logout"
            width="140px"
            height="51px"
            onClick={() => loginWithRedirect()}
          >
            Sign In
          </PrimaryIconButton>
        )}
      </SessionHeader>
    </Wrapper>
  );
};