import { BESTOW_THEME } from '@bestowinc/kindred-ds'

const { colors: { gray } } = BESTOW_THEME;

export const IconCaretDown = () =>
(
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00006 7.01025C5.89835 7.01133 5.79749 6.9918 5.70353 6.95285C5.60958 6.91389 5.52449 6.85631 5.45339 6.78359L0.366722 1.69692C0.290167 1.61847 0.247314 1.5132 0.247314 1.40359C0.247314 1.29397 0.290167 1.1887 0.366722 1.11025C0.446294 1.03327 0.552674 0.990234 0.663389 0.990234C0.774104 0.990234 0.880484 1.03327 0.960056 1.11025L6.00006 6.15692L11.0401 1.12359C11.1196 1.0466 11.226 1.00357 11.3367 1.00357C11.4474 1.00357 11.5538 1.0466 11.6334 1.12359C11.7099 1.20204 11.7528 1.30731 11.7528 1.41692C11.7528 1.52653 11.7099 1.6318 11.6334 1.71025L6.54672 6.79692C6.47451 6.86724 6.38892 6.92236 6.29501 6.959C6.20111 6.99565 6.10081 7.01307 6.00006 7.01025Z"
      fill={gray[600]} />
  </svg>

)
