import React, { SVGProps } from 'react';

export const IconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g stroke="#144352" transform="translate(-1128 -720)">
        <g transform="translate(452 274)">
          <g transform="translate(0 394)">
            <g transform="translate(672 48)">
              <g transform="translate(4 4)">
                <path d="M6.5 13.5l8.172-8.171a2.83 2.83 0 00-4-4L2.5 9.5" />
                <path d="M2.5 9.5L0.5 15.5" />
                <path d="M6.5 13.5L0.5 15.5" />
                <path d="M13.672 6.329a7.555 7.555 0 00-4-4" />
                <path d="M6.5 13.5a7.555 7.555 0 00-4-4" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);