/* eslint-disable camelcase */ // Note: disabled due to our go lang backend reponses come in underscore format
import { axiosRequest } from './core';

export const downloadAgentReportingInfo = (
  requestBody: {
    date_from: string | null;
    date_to: string | null;
  },
  reportVersion: string,
  apiVersion?: string
): Promise<{
  document_url: string;
}> => {
  if (!apiVersion) {
    apiVersion = 'v1';
  }

  return axiosRequest(`/${apiVersion}/ags/generate-agent-report`, {
    method: 'POST',
    data: {
      ...requestBody,
      version: reportVersion
    }
  });
}