import React, { FC } from 'react';
import { MaintenanceHeader, MaintenanceText, MaintenanceWrapper } from './DownForMaintenance.styled';
import { MaintenanceIcon } from '../icons/MaintenanceIcon';

// Utils
import { getMaintenanceMessaging } from '../../utils/maintenance';

const DownForMaintenance: FC = () => {
  const errorMeta = getMaintenanceMessaging();
  const { header, message } = errorMeta;

  return (
    <MaintenanceWrapper data-testid="maintenance-wrapper">
      <MaintenanceIcon />
      <MaintenanceHeader>{header}</MaintenanceHeader>
      <MaintenanceText data-testid="maintenance-message">{message}</MaintenanceText>
    </MaintenanceWrapper>
  );
};

export default DownForMaintenance;