import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './App';
import DownForMaintenance from './components/DownForMaintenance/DownForMaintenance'
import { store } from './redux/store';
import { blockTraffic } from './utils/maintenance';
import { Auth0Provider } from '@auth0/auth0-react';

import FeatureFlagProvider from './utils/featureFlagProvider';

(async () => {
  const LDProvider = await FeatureFlagProvider();

  ReactDOM.render(
    <Provider store={store}>
      <Auth0Provider
        audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ''}
        redirectUri={window.location.origin}
        scope={process.env.REACT_APP_AUTH0_SCOPE}
        cacheLocation="localstorage"
        // @ts-ignore
        useRefreshTokens={!window.Cypresss}
      >
        <LDProvider>
          {
            blockTraffic() ?
              <DownForMaintenance /> :
              <App />
          }
        </LDProvider>
      </Auth0Provider>
    </Provider>,
    document.getElementById('root')
  );
})();
