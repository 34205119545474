import styled from 'styled-components/macro';
import { mediaQueries } from '../../utils/constants';
import { BESTOW_THEME, Button } from '@bestowinc/kindred-ds';
import { HR } from '../HR.styled';
import { IconBackArrow } from '../icons/IconBackArrow';
import { isOldTheme } from '../../utils/utils';

export const BottomInputFooter = styled.div`
  display:grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${BESTOW_THEME.spacing[12]};
  border-top: 1px solid rgb(218, 215, 214);
  margin: 0 auto;
  align-items: center;
  padding: ${BESTOW_THEME.spacing[12]};
  background-color: ${BESTOW_THEME.colors.white};

  ${mediaQueries.mobile} {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
  }
`;

export const MobileButtonDivider = styled(HR)`
  margin: ${BESTOW_THEME.spacing[12]} 0;
  grid-column: span 2;
`;

// A little dot to show which day is today
export const TodayDot = styled.div`
  position: absolute;
  bottom: -7px;
  left: 15px;
  height: 5px;
  width: 5px;
  background: ${BESTOW_THEME.colors.blue[900]};
  border-radius: 50%;

  ${mediaQueries.mobile} {
    left: 17px;
  }

  ${mediaQueries.tablet} {
    left: 27px;
  }
`;

export const DateRangePickerWrapper = styled.div`
border-radius: 3px;
position: relative;

  ${mediaQueries.mobile} {
    height: 18.4375rem;
    margin: 0 auto;
    box-shadow: none;
  }
  
  ${mediaQueries.desktop} {
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
    ${isOldTheme && `
      width: 618px;
    `}
  }

  .DateRangePicker {
    position: relative;
    display: inline-block;
  }

  .PresetDateRangePicker_panel {
    padding: 0 22px 11px;
  }

  .DayPicker {
    margin: 0 auto;

    ${mediaQueries.mobile} {
      height: 295px;
    }

    ${mediaQueries.tablet} {
      height: 295px;
    }
  }

  .DayPicker_transitionContainer {
    height: auto;

    ${mediaQueries.tablet} {
      // Full view height minus headers, date inputs and buttons
      height: calc(100vh - 266px) !important;
    }

    ${mediaQueries.mobile} {
      // Full view height minus headers, date inputs and buttons
      height: calc(100vh - 266px) !important;
    }
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 22px;
  }

  .DayPickerNavigation .DayPickerNavigation_button:first-child,
  .DayPickerNavigation .DayPickerNavigation_button:last-child {
    ${mediaQueries.tablet} {
      top: 5px;
    }
  }

  .DayPickerNavigation .DayPickerNavigation_button:first-child {
    left: 24px;

    ${mediaQueries.tablet} {
      left: 39px;
    }
  }

  .DayPickerNavigation .DayPickerNavigation_button:last-child {
    right: 37px;

    ${mediaQueries.mobile} {
      right: 24px;
    }
  }

  // Disable the tooltip
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: ${BESTOW_THEME.fontSize[14]};
    text-align: center;
    font-weight: ${BESTOW_THEME.fontWeight.normal};
    border-radius: 4px;
  }

  .CalendarDay:active {
    outline: 0;
  }

  // Default - unselected
  .CalendarDay__default {
    // Need to have a border here otherwise the sizing of the box will get off
    border: solid 1px rgba(0, 0, 0, 0);
    color: ${BESTOW_THEME.colors.gray[600]};
    background: ${BESTOW_THEME.colors.white};
  }

  .CalendarDay__default:hover {
    background:${BESTOW_THEME.colors.green[400]};
    color: inherit;
  }

  // First and last box have curved borders
  .CalendarDay__selected_start {
    border-radius: 4px 0px 0px 4px;
  }

  .CalendarDay__selected_end {
    border-radius: 0px 4px 4px 0px;
  }

  .CalendarDay__selected_start.CalendarDay__selected_end  {
    border-radius: 4px;
  }

  // Color the dates inbetween the start and end
  .CalendarDay__selected_span {
    border-radius: 0px;
    background: ${BESTOW_THEME.colors.green[400]};
    border: 1px solid rgba(0, 0, 0, 0);
    color: ${BESTOW_THEME.colors.gray[700]};
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    border-radius: 0px;
    background: ${BESTOW_THEME.colors.gray[600]}
  }

  // Currently selected/active day
  .CalendarDay__selected,
  .CalendarDay__selected:active {
    background: ${BESTOW_THEME.colors.blue[900]};
    border: 1px solid rgba(0, 0, 0, 0);
    color: ${BESTOW_THEME.colors.white};
    ${TodayDot} {
      background: ${BESTOW_THEME.colors.white};
    }
  }
  .CalendarDay__selected:hover {
    background: ${BESTOW_THEME.colors.blue[900]};
    color: ${BESTOW_THEME.colors.white};
  }

  // When you have the first date selected but not the first these are the styles
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    border-radius: 0px;
    background: ${BESTOW_THEME.colors.green[400]};
    border: 1px double rgba(0, 0, 0, 0);
  }

  .CalendarDay__hovered_span:active {
    background: ${BESTOW_THEME.colors.blue[900]};
    border: 1px double ${BESTOW_THEME.colors.blue[900]};
    color: ${BESTOW_THEME.colors.white};
  }

  // The inactive dates
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: ${BESTOW_THEME.colors.white};
    border: 1px solid rgba(0, 0, 0, 0);
    color: ${BESTOW_THEME.colors.gray[300]};
  }

  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: unset;

    ${mediaQueries.mobile} {
      width: 100%;
    }

    ${mediaQueries.tablet} {
      width: 100%;
    }
  }

  // The month caption at the top
  .CalendarMonth_caption {
    color:${BESTOW_THEME.colors.gray[700]};
    font-size: ${BESTOW_THEME.fontSize[16]};
    text-align: center;
    font-family: ${BESTOW_THEME.fontFamily.sans};
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial;

    ${mediaQueries.tablet} {
      padding-top: 0.25rem;
    }
  }

  .CalendarMonthGrid {
    text-align: left;
    z-index: 0;
    
    ${mediaQueries.mobile} {
      width: 100% !important;
    }

    ${mediaQueries.tablet} {
      width: 100% !important;
    }
  }

  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
  }

  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .DayPickerNavigation {
    position: relative;
    z-index: 2;
  }

  .DayPickerNavigation__horizontal {
    height: 0;
  }

  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0;
  }

  .DayPickerNavigation__bottom {
    height: auto;
  }

  .DayPickerNavigation__bottomDefault {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
  }

  .DayPickerNavigation_button__default {
    border: 1px solid ${BESTOW_THEME.colors.gray[200]};
    background-color: ${BESTOW_THEME.colors.white};
    color: ${BESTOW_THEME.colors.gray[600]};
  }

  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 1px solid ${BESTOW_THEME.colors.gray[300]};
  }

  .DayPickerNavigation_button__default:active {
    background: ${BESTOW_THEME.colors.gray[150]};
  }

  .DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid ${BESTOW_THEME.colors.gray[150]};
  }
  
  .DayPickerNavigation_button__disabled:focus,
  .DayPickerNavigation_button__disabled:hover {
    border: 1px solid ${BESTOW_THEME.colors.gray[150]};
  }

  .DayPickerNavigation_button__disabled:active {
    background: 0 0;
  }

  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;
  }

  .DayPickerNavigation_bottomButton__horizontalDefault {
    position: static;
    margin: -10px 22px 30px;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px;
  }

  .DayPicker {
    background: ${BESTOW_THEME.colors.white};
    position: relative;
    text-align: left;
    overflow: hidden;
  }

  .DayPicker__horizontal {
    background: ${BESTOW_THEME.colors.white};
  }

  .DayPicker__hidden {
    visibility: hidden;
  }

  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .DayPicker_portal__vertical {
    position: initial;
  }

  .DayPicker_focusRegion {
    outline: 0;
  }

  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top;
  }

  .DayPicker_weekHeaders {
    position: relative;

    ${mediaQueries.mobile} {
      top: 20px;
    }
  }
  
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px;
  }

  .DayPicker_weekHeader {
    z-index: 2;
    position: absolute;
    padding: 0 5px !important;
    color: ${BESTOW_THEME.colors.gray[800]};
    text-align: left;

    ${mediaQueries.mobile} {
      top: 37px;
      left: 9px;
      width: 100%;
      margin-left: 0 !important;
    }

    ${mediaQueries.tablet} {
      top: 37px;
      left: 9px;
      width: 100%;
      margin-left: 0 !important;
    }

    ${mediaQueries.desktop} {
      top: 62px;
      left: 0;
    }
  }

  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
    font-weight: 500;

    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;
  }
`;

// This container gives the svg a bigger hitbox
export const IconContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${BESTOW_THEME.spacing[12]};

  ${mediaQueries.mobile} {
    width: 100%;
    grid-column: span 2;
  }
`;

export const MobileHeaderContainer = styled.div`
  position: relative;

  & > div {
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 36px;
    height: 36px;
  }
  
  & > div > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const MobileHeader = styled.h2`
  margin-top: 0;
  padding: 1rem;
  border-bottom: 1px solid ${BESTOW_THEME.colors.gray[200]};
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  color: ${BESTOW_THEME.colors.gray[900]};
`;

export const DatePickerButton = styled(Button)`
  font-weight: ${BESTOW_THEME.fontWeight.normal};
  font-size: ${BESTOW_THEME.fontSize[14]};
  height: 2rem;

  ${mediaQueries.tablet} {
    max-width: 140px;
  }
`;

export const ClearButton = styled(DatePickerButton)`
  padding-left: inherit;
  background: none !important;
  color: ${BESTOW_THEME.colors.blue[900]};
  text-align: center;

  ${mediaQueries.tablet} {
    display: inline-block;
    max-width: 140px;
    display: inline-block;
    padding-left: 0;
    text-align: left;
  }
`;

export const SelectButton = styled(DatePickerButton)`
  font-weight: ${BESTOW_THEME.fontWeight.medium};
  height: 32px;
`;

export const RenderDayContentDiv = styled.div`
  position: relative;
`;

export const LeftArrow = styled(IconBackArrow)`
  ${mediaQueries.mobile} {
    transform: rotate(90deg);
  }
`;

export const RightArrow = styled(IconBackArrow)`
  transform: rotate(180deg);

  ${mediaQueries.mobile} {
    transform: rotate(-90deg);
  }
`;