import styled from 'styled-components';

import { mediaQueries } from '../../../utils/constants';
import { IconExit } from '../../icons/IconExit';
import { THEME } from './../Theme';
import { Text } from '@bestowinc/kindred-ds';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  height: 5rem;
  width: 100%;
  background: ${THEME.colors.white};
  box-shadow: 0 2px 3px 0 rgba(1, 100, 80, 0.13);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .75rem 0 1rem;
  font-family: Montserrat, sans-serif;

  ${mediaQueries.desktop} {
    padding: 0 1rem 0 1.5rem;
  }
`;

export const WelcomeParagraph = styled.p`
  font-weight: bold;
  margin-right: 1.5rem;

  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0px;
  line-height: 1.25rem;
`;

export const SessionHeader = styled.div`
  display: flex; 
  align-items: center;
  ${mediaQueries.mobile} {
    display: block;
  }
`;

export const LogoWrapper = styled.div`
  display: flex; cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex; align-items: center;
`;

export const CustomIconExit = styled(IconExit)`
  padding-left: 5px
`;

export interface ButtonPropTypes {
  height?: string;
  width?: string;
}

export const PrimaryButton = styled.button<ButtonPropTypes>`
  transition: all 0.3s;
  font-family: ${THEME.fonts.montserrat};
  border-width: 0;
  cursor: pointer;
  width: ${$props => $props.width || '100%'};
  height: ${$props => $props.height || '48px'};
  font-weight: ${THEME.fontWeights.bold};
  font-size: ${THEME.fontSizes.button};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${THEME.colors.white};
  background-color: ${THEME.colors.navy700};
  padding: 0.5rem 0.75rem;
  border-radius: 1px;
  &:hover {
    //TODO: THEMIFY THIS COLOR
    background-color: rgba(11, 53, 65, 0.8);
  }
  &:not(:disabled):not(.disabled):active {
    &:focus {
      box-shadow: none;
    }
  }
  &:focus {
    box-shadow: none;
    //TODO: THEMIFY THIS COLOR
    background-color: rgba(0, 28, 40, 0.5);
  }
  &:disabled {
    // TODO: THEMIFY THESE COLORS
    background: #d1d3d3;
    color: #979797;
  }
`;

export const PrimaryIconButton = styled(PrimaryButton)`
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
`;

export const Paragraph = styled.p`
  color: ${THEME.colors.charcoal900};
  font-family: ${THEME.fonts.montserrat};
  font-size: ${THEME.fontSizes.paragraph};
  font-weight: ${THEME.fontWeights.normal};
  letter-spacing: 0px;
  line-height: 1.25rem;
`;

export const CardButton = styled.button<{ isActive?: boolean }>`
  margin-bottom: 1rem;
  background-color: ${THEME.colors.white};
  font-family: ${THEME.fonts.montserrat};
  font-weight: ${THEME.fontWeights.medium};
  font-size: ${THEME.fontSizes.button};
  border-radius: 2px;
  border: 1px solid ${THEME.colors.gray300};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding: 1.6rem 0 0;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    font-weight: ${THEME.fontWeights.bold};
    background-color: ${THEME.colors.mint};
    border: 1px solid ${THEME.colors.cta};
  `}
  
  @media screen and (min-width: 560px) {
    width: 13.75rem;
    margin-bottom: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ReportHeader = styled(Text).attrs({
  element: 'h1',
  variant: 'titleLarge'
})`
    font-size: 1.75rem;
    font-family:${({ theme }) => theme.fontFamily.serif};
    color: ${({ theme }) => theme.colors.tertiary};
    line-height: 1.875rem;
    margin-bottom: 0.625rem;
`;
