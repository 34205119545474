import { Moment } from 'moment';
import { TimezonePopup } from '../../TimeZonePopUp';
import { ReportParagraph, ReportTypeSpan } from './ReportDownloadView.styled';
import { Tooltip } from './Tooltip';
import { ReportViewType } from '../../ReportType';
import { StyledGlobe } from '../../icons/IconStyles';

interface ReportTypeProps {
  reportViewType: ReportViewType;
  today: Moment;
  todayUTC: Moment;
  timezoneName: string;
  timezoneAbbr: string;
  tooltipType: string;
}

export const ReportType = ({ reportViewType, today, todayUTC, timezoneName, timezoneAbbr, tooltipType }: ReportTypeProps) => {
  const reportText = {
    last60: 'Applications opened in the last 60 days.',
    customDateRange: 'Applications opened in the specified date range.',
    default: 'Unknown Report Type.'
  }

  return (
    <>
      <ReportParagraph>
        {reportText[reportViewType] ?? reportText.default}&nbsp;
        <ReportTypeSpan>
          UTC <StyledGlobe data-tip={tooltipType} data-for={tooltipType} />
        </ReportTypeSpan>
      </ReportParagraph>
      <Tooltip
        id={tooltipType}
        tooltipContent={
          <TimezonePopup
            today={today}
            todayUTC={todayUTC}
            timezoneName={timezoneName}
            timezoneAbbr={timezoneAbbr}
          />
        }
        placement="bottom"
      />
    </>
  )
};