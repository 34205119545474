import { Action } from 'redux';

export type LoadingReducerState = {
  AGENT_REPORT_FETCH: boolean;
}

const initialState = {
  AGENT_REPORT_FETCH: false
}

export const loadingReducer = (state = initialState, action: Action): LoadingReducerState => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if (!matches) {
      return state;
    }

    const [, requestPrefix, requestState] = matches;

    return {
      ...state,
      // Store whether a request is happening at the moment or not
      // e.g. will be true when receiving AGENT_REPORT_FETCH_REQUEST
      //      and false when receiving AGENT_REPORT_FETCH_SUCCESS / AGENT_REPORT_FETCH_ERROR
      [requestPrefix]: requestState === 'REQUEST'
    };
};
