import { FC, useState } from 'react';
import {
  Wrapper,
  HeaderTitle,
  SessionHeader,
  HeaderSubTitle,
  TitleContainer,
  MenuContainer,
  MenuSettings,
  MenuName,
  MenuEmail,
  MenuLink,
  IconContainer,
  HeaderWrapper,
  NameContainer,
  Menu
} from './Header.styled'
import { useAuth0 } from '@auth0/auth0-react';
import { IconCaretDown } from './components/icons/IconCaretDown';
import { Button, Divider, PortalLogoLockup, BestowSlashLogo } from '@bestowinc/kindred-ds';
import { isMobile } from './utils/constants';

export const Header: FC = () => {
  const { user, logout, isAuthenticated, loginWithRedirect } = useAuth0();
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();

  return (
    <HeaderWrapper>
      <Wrapper>
        {!isMobile && <PortalLogoLockup text="Agent Reporting" />}
        {isMobile && <BestowSlashLogo />}
        <SessionHeader
          data-testid="menu"
          onClick={() => setIsMenuVisible(!isMenuVisible)}
          ref={el => {
            if (el) {
              setAnchorElement(el);
            }
          }}
        >
          {
            isAuthenticated ?
              <>
                <TitleContainer>
                  <HeaderTitle>
                    {
                      /**
                       * TODO:  Identity from Request
                       * SureLC Entity Name
                       */
                      ''
                    }
                  </HeaderTitle>
                  <HeaderSubTitle>
                    {user?.name}
                  </HeaderSubTitle>
                </TitleContainer>
                <IconContainer>
                  <IconCaretDown />
                </IconContainer>
              </>
              : (
                <Button
                  data-testid="signIn-button"
                  id="button-logout"
                  width="140px"
                  height="51px"
                  onClick={() => loginWithRedirect()}
                >
                  Sign In
                </Button>
              )}
        </SessionHeader>
      </Wrapper>
      <Menu
        setWidthToAnchor={false}
        width="320px"
        zIndex="1"
        onClickAway={() => setIsMenuVisible(false)}
        visible={isMenuVisible}
        anchorElement={anchorElement}
      >
        <MenuContainer>
          <NameContainer>
            <MenuName>{user?.name}</MenuName>
            <MenuEmail>{user?.email}</MenuEmail>
          </NameContainer>
          <MenuSettings>
            {
              /**
               * TODO: Remove false flag in order to show Settings link
               * until Settings are created
               */
              false && <MenuLink>Settings</MenuLink>
            }
            <MenuLink
              aria-label="Logout"
              data-testid="logout-button"
              onClick={() => logout({ returnTo: window.location.origin })}
            >Log out</MenuLink>
          </MenuSettings>
        </MenuContainer>
      </Menu>
      <Divider />
    </HeaderWrapper>
  );
};