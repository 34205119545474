import { Moment } from 'moment';

import { StyledGlobe } from './icons/IconStyles';
import { TimezonePopup } from './TimeZonePopUp';

import {
    ReportTypeSpan,
    ReportParagraph,
    DottedSpan,
    Tooltip
} from './../views/ReportDownloadView.styled';

export enum ReportViewType {
    'last60' = 'last60',
    'customDateRange' = 'customDateRange',
    'allTime' = 'allTime'
}

interface ReportTypeProps {
    today: Moment;
    todayUTC: Moment;
    timezoneName: string;
    timezoneAbbr: string;
    tooltipType: string;
}

export const ReportType = ({ today, todayUTC, timezoneName, timezoneAbbr, tooltipType }: ReportTypeProps) => {
    return (
        <ReportParagraph>
            <Tooltip
                id="timeTooltip"
                maxWidth="600px"
                background="#ffffff"
                color="#000000"
                tooltipPosition="bottom"
                tooltipMessage={
                    <TimezonePopup
                        today={today}
                        todayUTC={todayUTC}
                        timezoneName={timezoneName}
                        timezoneAbbr={timezoneAbbr}
                    />
                }
            >
                <span>
                    All dates and times in&nbsp;
                    <ReportTypeSpan>
                        <DottedSpan> UTC </DottedSpan>
                        <StyledGlobe data-tip={tooltipType} data-for={tooltipType} />
                    </ReportTypeSpan>
                </span>
            </Tooltip>
        </ReportParagraph >
    )
};
