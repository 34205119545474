import { SVGProps } from 'react';

export const IconClock = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg width="18.75px" height="18.75px" viewBox="0 0 34 34" {...props}>
    <title>clock-icon</title>
    <g
      id="MVP"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Option-A---Latest-Activity-V3"
        transform="translate(-343.000000, -296.000000)"
        stroke="#0A303B"
      >
        <path
          d="M360,329 C368.836556,329 376,321.836556 376,313 C376,304.163444 368.836556,297 360,297 C351.163444,297 344,304.163444 344,313 C344,321.836556 351.163444,329 360,329 Z M368.347826,321.347826 L361.470609,314.472 M360,305.347826 L360,310.913043 M360,315.086957 C361.152594,315.086957 362.086957,314.152594 362.086957,313 C362.086957,311.847406 361.152594,310.913043 360,310.913043 C358.847406,310.913043 357.913043,311.847406 357.913043,313 C357.913043,314.152594 358.847406,315.086957 360,315.086957 Z"
          id="clock-icon"
        />
      </g>
    </g>
  </svg>
);
