import { Moment } from 'moment';

import {
    ModalContainer,
    TimezoneParagraph
} from './../views/ReportDownloadView.styled';
import { HR } from './HR.styled';

interface TimezonePopupProps {
    today: Moment;
    todayUTC: Moment;
    timezoneName: string;
    timezoneAbbr: string;
}

const dayTimeFormat = 'h:mm A';

export const TimezonePopup = ({ today, todayUTC, timezoneName, timezoneAbbr }: TimezonePopupProps) => (
    <ModalContainer>
        <TimezoneParagraph>
            <span>
                <b>UTC -</b> Universal Time Coordinated
            </span>
            <b>{todayUTC.format(dayTimeFormat)}</b>
        </TimezoneParagraph>
        <HR />
        <TimezoneParagraph>
            <span>
                <b>{timezoneAbbr} - </b> {timezoneName}
            </span>
            <b>{today.format(dayTimeFormat)}</b>
        </TimezoneParagraph>
    </ModalContainer>
);