import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { mediaQueries } from '../../../utils/constants';
import { THEME } from '../Theme';

/* Before future devs freak out about the large pile of importants,
   please refer to the following documentation on react-tooltip under
   the className option: https://www.npmjs.com/package/react-tooltip
*/
export const TooltipElement = styled(ReactTooltip)`
  background: ${THEME.colors.white} !important;
  color: ${THEME.colors.navy700} !important;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgb(240, 242, 242) !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  padding: 0 !important;
  pointer-events: auto !important;
  &:before,
  &:after {
    display: none !important;
  }
  margin-top: 6px !important;
  min-width: 290px !important;
  
  ${mediaQueries.mobile} {
    left: 15px !important;
  }

  ${mediaQueries.tablet} {
    left: 25px !important;
  }
`;