import { ReactElement, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react'

import { Unauthorized } from '../Unauthorized';
import { isUserAuthorized } from '../utils/utils';

interface ProtectedRouteProps {
    children: JSX.Element
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const isCancelled = useRef(false);
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [isAuthorized, setIsAuthorize] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();

                if (!isCancelled.current) {
                    setIsAuthorize(isUserAuthorized(token))
                }
            } catch (err: any) {
                if (err.error === 'login_required' && !isCancelled.current) {
                    loginWithRedirect();
                }
            }
        })();

        return () => {
            isCancelled.current = true;
        }
    }, [getAccessTokenSilently, loginWithRedirect]);

    if (isAuthorized) {
        return (children as ReactElement);
    }

    return (
        <Unauthorized />
    );
}